var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "결과 정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.isOld &&
                          !_vm.disabled &&
                          _vm.editable &&
                          !_vm.popupParam.isApprContent
                            ? _c("c-btn", {
                                attrs: {
                                  label: "LBLREMOVE",
                                  icon: "delete_forever",
                                },
                                on: { btnClicked: _vm.removePlan },
                              })
                            : _vm._e(),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.disabled && _vm.editable,
                                expression: "!disabled && editable",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.patrol,
                              mappingType: _vm.saveType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.savePlan,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable && !_vm.isOld,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.patrol.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.patrol, "plantCd", $$v)
                            },
                            expression: "patrol.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: "",
                            type: "edit",
                            codeGroupCd: "PATROL_TYPE_FST_CD",
                            disabled: _vm.popupParam.typeDisabled,
                            itemText: "codeName",
                            itemValue: "code",
                            label: "상위구분",
                            name: "patrolFstCd",
                          },
                          model: {
                            value: _vm.patrol.patrolFstCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.patrol, "patrolFstCd", $$v)
                            },
                            expression: "patrol.patrolFstCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required:
                              _vm.patrol.patrolFstCd === "PTF0000001" ||
                              _vm.patrol.patrolFstCd === "PTF0000005",
                            type: "edit",
                            codeGroupCd: "PATROL_TYPE_SEC_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "하위구분",
                            name: "patrolSecCd",
                          },
                          model: {
                            value: _vm.patrol.patrolSecCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.patrol, "patrolSecCd", $$v)
                            },
                            expression: "patrol.patrolSecCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "추진부서",
                            name: "deptCd",
                          },
                          on: { setDeptName: _vm.setDeptName },
                          model: {
                            value: _vm.patrol.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.patrol, "deptCd", $$v)
                            },
                            expression: "patrol.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "추진일정",
                            type: "date",
                            name: "patrolDate",
                          },
                          model: {
                            value: _vm.patrol.patrolDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.patrol, "patrolDate", $$v)
                            },
                            expression: "patrol.patrolDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                      [
                        _c("c-construnction", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            name: "sopConstructionId",
                          },
                          model: {
                            value: _vm.patrol.sopConstructionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.patrol, "sopConstructionId", $$v)
                            },
                            expression: "patrol.sopConstructionId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "비고",
                            name: "remark",
                          },
                          model: {
                            value: _vm.patrol.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.patrol, "remark", $$v)
                            },
                            expression: "patrol.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "grid",
                  attrs: {
                    title: "지적사항 및 개선목록",
                    columns: _vm.grid.columns,
                    data: _vm.grid.data,
                    editable: false,
                    changeData: _vm.changeData,
                    gridHeight: _vm.gridHeight,
                    usePaging: false,
                    filtering: false,
                    columnSetting: false,
                  },
                  on: {
                    linkClick: (row, col, idx) =>
                      _vm.linkClick(row, row.ibmClassCd, row.sopImprovementId),
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "suffixTitle" },
                    [
                      !_vm.isOld
                        ? _c(
                            "font",
                            {
                              staticClass: "text-negative",
                              staticStyle: {
                                "font-size": "0.8em",
                                "font-weight": "300",
                              },
                            },
                            [_vm._v(" ※ 먼저 신규 저장을 하세요. ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          !_vm.disabled && _vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "개선요청",
                                  icon: "add",
                                  color: "red-6",
                                },
                                on: { btnClicked: _vm.addImpr },
                              })
                            : _vm._e(),
                          !_vm.disabled && _vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "즉시조치",
                                  color: "light-blue",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addImmImpr },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }